@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #2d2d2d;
  border-radius: 44px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.at-sidebarwrapper::-webkit-scrollbar{display: none;}
.nav-list li a{
  color: #B8B8B8;
}
.nav-list li a.active,
.nav-list li a:hover{
  color: #EB6601;
}
.nav-list li a svg path,
.nav-list li a svg{
  fill: #B8B8B8 !important;
}
.nav-list li a.active svg,
.nav-list li a.active svg path,
.nav-list li a:hover svg path,
.nav-list li a:hover svg{
  fill: #EB6601 !important;
}
.at-scrollborzero::-webkit-scrollbar{display: none;}